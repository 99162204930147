#loading-screen {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 1;
  transition: 1s opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #fff;

  #loading-cube-wrapper {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  #wrap-cube-model {
    position: absolute;
    top: 0px;
    z-index: 1;

    &.disable-click {
      pointer-events: none;
    }
  }

  .slick-dots {
    bottom: 50px;

    li {
      margin: 0 2px;

      button:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }

  .slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
  }

  .slick-arrow {
    display: none !important;
  }

  .wrap-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.center-center {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    img {
      height: 100vh;
    }
  }

  .wrap-intro {
    &.hide {
      display: none;
    }

    &.show {
      display: unset;
    }
  }

  &.hide {
    display: none;
  }

  .intro-content {
    z-index: 1;
  }

  .wrap-slide-loading {
    transition: all 1s;

    .btn-skip-video {
      position: absolute;
      bottom: 80px;
      left: 45px;
      font-weight: 700;
      border-radius: 0px;
      font-size: 15px;
      line-height: 20.25px;
      background: #fff;
      width: 193px;
      height: 51px;
      @media only screen and (max-width: 1366px) {
        bottom: 150px;
      }
    }
    .wrap-content {
      position: absolute;
      bottom: 50px;
      left: 50px;
      text-align: left;

      .wrap-line {
        display: flex;
        border-top: 1px solid #fff;

        .left,
        .right {
          padding: 10px;
        }

        .left {
          width: 75%;
          border-right: 1px solid #fff;
        }

        .right {
          width: 25%;
          letter-spacing: 0.2rem;
        }
      }
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    &.show {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

#intro-video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-video-2 {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  transform: translateX(-50%);
  object-fit: fill;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-img {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}

.intro-content {
  .wrap-button {
    height: 102px;
  }

  h1 {
    font-size: calc(2rem + 2vw);
    font-family: 'Philosopher', serif;
    margin-bottom: 1rem;
  }

  p {
    font-size: calc(1rem + 0.125vw);
    letter-spacing: 0.125rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}

.wrap-text {
  margin-bottom: 3rem;
}

.wrap-button {
  button {
    background: #ffffff;
    color: #000000;
    padding: 16px;
    font-weight: bold;
    border: 1px solid #000;
    font-size: 14px;
    width: 170px;
    // font-family: system-ui;
  }
}
