$cms-page-bg: #fff;
$cms-page-color: #000000;
$cms-input: #ffdb00;

.wrap-cms-page {
  min-height: 100vh;
  background: $cms-page-bg;

  .wrap-top-nav .nav-menu ul li.active div::before {
    opacity: 1 !important;
    width: 97px !important;
  }

  .wrap-top-nav .nav-menu ul li:not(.placeholder) div::before {
    content: '';
    height: 1px;
    width: 0;
    background: #FFDB00;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s;
  }

  .wrap-top-nav {
    padding-left: 75px;
    padding-right: 75px;
    .nav-menu {
      .right {
        li:last-of-type {
          span {
            text-align: left;
            transform: translateX(-115px);
          }
          &:hover {
            span {
              &::before {
                width: 70px;
              }
            }
          }
        }
        li:first-of-type {
          span {
            text-align: left;
            transform: translateX(30px);
          }
          &:hover {
            span {
              &::before {
                width: 40px;
              }
            }
          }

          &.active {
            span {
              &::before {
                width: 40px;
              }
            }
          }
        }
      }
      .left {
        li:first-of-type {
          span {
            text-align: right;
            transform: translateX(50px);
          }
        }
        li:last-of-type {
          span {
            text-align: right;
            transform: translateX(-30px);
          }
        }
      }
    }
  }

  .wrap-top-nav {
    position: sticky;
    background: $cms-page-bg;
    color: $cms-page-color;
    height: 165px;

    .nav-menu ul li {
      opacity: 1;
    }

    .nav-menu ul li div {
      color: $cms-page-color;
      font-weight: 700;
      font-size: 14px;
      padding: 6px 12px;
    }
  }

  .cube__face--front {
    border-left: 2.16667px solid $cms-page-color;
    border-right: 3.25px solid $cms-page-color;
    border-bottom: 3.25px solid $cms-page-color;
    border-top: 1.625px solid $cms-page-color;
    transform: rotateY(0deg) translateZ(12px);
  }

  .cube__face--back {
    border-left: 1.625px solid #212529;
    border-right: 1.625px solid #212529;
    border-bottom: 3.25px solid #212529;
    transform: rotateY(180deg) translateZ(12px);
  }

  .cube__face--right {
    border-left: 3.25px solid #212529;
    border-right: 1.625px solid #212529;
    border-bottom: 3.25px solid #212529;
    transform: rotateY(90deg) translateZ(12px);
  }

  .cube__face--left {
    border-left: 3.25px solid #212529;
    border-right: 3.25px solid #212529;
    border-bottom: 3.25px solid #212529;
    border-top: 1.625px solid #212529;
    transform: rotateY(-90deg) translateZ(12px);
  }

  .cube__face--top {
    border-top: 3.25px solid #212529;
    border-right: 3.25px solid #212529;
    border-bottom: 3.25px solid #212529;
    border-left: 3.25px solid #212529;
    transform: rotateX(90deg) translateZ(12px);
  }

  .cube__face {
    background: white;
  }

  .page-container {
    position: relative;
    min-height: calc(100vh - 165px);
    padding-right: 75px;
    padding-left: 75px;
  }

  .page-header {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    position: sticky;
    top: 165px;
    z-index: 100;
    background: $cms-page-bg;

    .f-title {
      font-size: 28px;

      &::before {
        content: "";
        position: absolute;
        width: 100px;
        bottom: 10px;
        border-bottom: 2px solid $cms-page-color;
      }
    }

    &__input-search {
      max-width: 450px;

      .form-control {
        color: #414345;
        border-right: 0;
        font-size: 14px;
        border: 0;
        border-bottom: 2px solid $cms-page-color;
        border-radius: 0;
        padding: 0;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: $cms-page-color;
        }
      }
    }

    .btn {
      background: $cms-page-bg;
      color: $cms-page-color;

      &.sort-desc svg {
        transform: rotate(180deg);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .page-body {
    .table > :not(caption) > * > * {
      border-bottom-width: 0px;
    }

    .table-striped > tbody > tr:nth-of-type(odd) {
      --bs-table-accent-bg: $cms-page-bg;
    }

    .table-responsive-custom {
      .table-custom {
        width: 100%;
      }

      thead {
        color: $cms-page-bg;
        background: $cms-page-color;
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 10px;

        @media only screen and (max-width: 1366px) {
          .header-tenancy {
            padding-right: 30px;
          }
          .header-expiry {
            padding-right: 100px;
          }
          .header-level,
          .header-training,
          .header-precinct {
            padding-bottom: 45px;
          }
        }

        tr {
          display: "table";
          width: "100%";
          table-layout: "auto";
        }

        th:first-of-type {
          padding-left: 5px;
        }

        th {
          font-weight: 500;
          padding-top: 20px;
          padding-bottom: 20px;

          .btn-edit {
            background: $cms-page-bg;
            color: $cms-page-color;
            padding: 0 10px 4px;
            font-size: 12px;
            font-weight: 700;
            line-height: 8px;
            position: relative;
            border: 0px;
            left: 10px;
            bottom: 3px;
          }
        }
      }

      tbody {
        background: $cms-page-bg;
        border-left: 1px solid $cms-page-color;
        border-bottom: 1px solid $cms-page-color;
        border-right: 1px solid $cms-page-color;

        @media only screen and (max-width: 1366px) {
          height: calc(100vh - 500px);
        }

        tr {
          td {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: $cms-page-color;
            .btn-more {
              line-height: 6px;
              padding: 0 8px 6px;
              margin-left: 30%;
              background: #f0f0ea;

              &:focus {
                box-shadow: none;
              }
            }

            border-right: 0.5px solid black;
            padding: 10px 5px 10px 5px;

            .custom-input {
              position: relative;
              border: none;
              background: $cms-input;
              width: 100%;
              height: 25px;
              transform: translate(-2px, 0);
              // cursor: text;

              &:focus {
                outline: none;
              }
            }

            .form-check-input {
              height: 25px;
              border: 0;
              background-color: #e4e4da;
              width: 50px;

              &:focus {
                box-shadow: none;
                border: 0;
                background-color: "8B8B8B";
              }
            }
          }

          .clickable {
            cursor: pointer;
          }

          .text-one-line {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          td:last-of-type {
            border-right: unset;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 30px;
  }

  ::-webkit-scrollbar-track {
    background: #ffff;
    border-left: 12px solid #eeeeee;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  ::-webkit-scrollbar-thumb {
    border-left: 12px solid #d4d4d4;
    background-color: #fff;
    max-height: 100px;
    height: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-left: 12px solid #d4d4d4;
    background-color: #fff;
  }

  .page-footer {
    margin-top: 20px;

    .btn {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .btn-outline {
    border: 1px solid #000000;
    border-radius: 0px;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #ffffff;
  }

  .modal-over-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;

    .modal-notes {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 34px 40px;
      background-color: $cms-page-bg;
      max-width: 700px;
      width: 100%;
      margin: auto;

      .modal-header {
        padding: unset;
        border: unset;

        .f-title {
          font-size: 28px;

          &::before {
            content: "";
            position: absolute;
            width: 100px;
            top: 80px;
            border-bottom: 2px solid $cms-page-color;
          }
        }
      }

      .modal-body {
        padding: 36px 134px 78px 0;
        border: unset;

        .modal-body-text {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.2;
          border: unset;
          width: 100%;
          outline: unset;
        }
      }

      .modal-footer {
        border: unset;
        display: flex;
        justify-content: flex-start;
      }

      .close-btn {
        position: absolute;
        top: 0;
        right: 0;

        border: unset;
        background-color: unset;
        color: $cms-page-color;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;
        padding: 16px;
        line-height: 15px;
        font-weight: 300;

        &:hover {
          background-color: $cms-page-bg;
        }
      }
    }
  }
}
.wrap-content-page {
  .nav-tabs {
    border-bottom: 0;
    display: flex;
    justify-content: center;

    .nav-link {
      border: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      color: #000000;
      display: flex;
      justify-content: center;
      border-bottom: 3px solid #000000;
      &:hover {
        border-color: #BBEFFF;
      }
      &.active {
        color: #000000;
        border-color: #BBEFFF;
      }

    }
  }
}
.wrap-content-image {
  padding-bottom: 20px;
  .page-body {
    .wrap-media-list {
      border: 1px solid #000000;
      padding: 64px 26px;
      .wrap-media-item {
        height: 200px;
        .media-image {
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .media-details {
          .media-title {
            margin-top: 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            margin-bottom: 13px;
          }
          .media-edit-btn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
          }
        }
      }
    }
    .wrap-media-actions {
      .btn-add-new-media {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}
.wrap-content-gallery {
  padding-bottom: 20px;
  .page-body {
    .wrap-gallery-list {
      border: 1px solid #000000;
      padding: 64px 26px;
      .wrap-gallery-item {
        height: 200px;
        .gallery-image {
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .gallery-details {
          .gallery-title {
            margin-top: 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            margin-bottom: 13px;
          }
          .gallery-edit-btn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
          }
        }
      }
    }
    .wrap-gallery-actions {
      .btn-add-new-gallery {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}
